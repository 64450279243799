@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:ital,wght@0,400..700;1,400..700&display=swap');

:root {
    --flowstate-green: #68855d;
    --flowstate-white: #fff;
    --flowstate-text-primary: #1C2518;
    --flowstate-text-secondary: #404a3c;
}

* {
    font-family: "Ibarra Real Nova", serif;
    font-optical-sizing: auto;
    font-style: normal;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}