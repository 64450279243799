#banner {
    width: 100%;
    height: 94.3vh;
    background-color: var(--flowstate-green);
    font-weight: bold;
    position: relative;
}

#background-image {
    background-image: url('../media/stable-front.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}

#content {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--flowstate-white);
    opacity: 0;
}

#banner a {
    color: var(--flowstate-white);
    text-decoration: none;
    scale: 1;
    transition: scale 300ms ease-in-out;
}

#banner a:hover {
    scale: 1.05;
    transition: scale 300ms ease-in-out;
}

#content.visible, #background-image.visible {
    opacity: 1;
    transition: all 800ms linear;
}

#background-image.visible {
    opacity: .35;
}

h1 {
    font-family: "Cinzel", serif;
}